import React from 'react'

import { ExternalLink } from '@/graphql/graphql'
import { ProfileUser } from '@/types'
import ContactForm from './form'

interface Props {
  data: ProfileUser | undefined
  platform?: ExternalLink
}

const ContactArea: React.FC<Props> = ({ data, platform }) => {
  return (
    <div
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-delay="200"
      data-aos-once="true"
      className="rn-contact-area section-height"
    >
      <div className="inner">
        <h5 className="title">Contact With Me</h5>
        <ContactForm userId={data?.id} platform={platform} />
      </div>
    </div>
  )
}

export default ContactArea
