/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { X } from 'react-feather'

import { CertificationInput } from '@/graphql/graphql'
import { KeyValue } from '@/types'
import { FormGroup, FormError, Input, Button, Label } from '@/components/index'

interface Props {
  show: boolean
  lastIndex: number
  data: Maybe<CertificationInput>
  onSave: (v: CertificationInput) => void
  onClose: () => void
}

const CertificationModal: React.FC<Props> = ({
  show,
  lastIndex,
  data,
  onSave,
  onClose,
}) => {
  const [userCertification, setUserCertification] =
    useState<CertificationInput>({} as CertificationInput)
  const [errors, setErrors] = useState<KeyValue<string>>({})

  useEffect(() => {
    if (!show) {
      setErrors({})
      setUserCertification({} as CertificationInput)
    }
  }, [show])

  useEffect(() => {
    if (data) {
      setUserCertification(data)
    } else {
      const _certification: CertificationInput = {
        index: lastIndex + 1,
        name: '',
        issuingOrg: '',
        issueDate: '',
        credentialId: '',
        credentialUrl: '',
        visible: true,
      }

      setUserCertification(_certification)
    }
  }, [data, lastIndex])

  const onChange = useCallback((name: string, value: string | boolean) => {
    setUserCertification(e => ({ ...e, [name]: value }))
  }, [])

  const validate = useCallback(() => {
    const _errors: KeyValue<string> = {}
    let valid = true

    if (!userCertification.name) {
      _errors.name = 'This field is required'
      valid = false
    }
    if (!userCertification.issueDate) {
      _errors.issueDate = 'This field is required'
      valid = false
    }
    if (!userCertification.issuingOrg) {
      _errors.issuingOrg = 'This field is required'
      valid = false
    }
    setErrors(_errors)

    return valid
  }, [
    userCertification.issueDate,
    userCertification.issuingOrg,
    userCertification.name,
  ])

  const _onSave = useCallback(
    (e?: any) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (!validate()) return

      onSave(userCertification)
    },
    [onSave, userCertification, validate],
  )

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title
          id="example-custom-modal-styling-title"
          className="sr-only"
        >
          Edit certification
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">
            <X />
          </span>
        </button>
      </Modal.Header>
      <Modal.Body className="resume-form-modal">
        <div className="form-wrapper form-dark no-shadow py-0 px-3">
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Input
                  id="name"
                  name="name"
                  value={userCertification.name || ''}
                  placeholder="Name"
                  type="text"
                  onChange={(e: any) => onChange('name', e.target.value)}
                  size="lg"
                />

                {!!errors.name && <FormError>{errors.name}</FormError>}
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Input
                  id="issuingOrg"
                  name="issuingOrg"
                  value={userCertification.issuingOrg || ''}
                  placeholder="Issuing organization"
                  type="text"
                  onChange={(e: any) => onChange('issuingOrg', e.target.value)}
                  size="lg"
                />

                {!!errors.issuingOrg && (
                  <FormError>{errors.issuingOrg}</FormError>
                )}
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Input
                  id="issueDate"
                  name="issueDate"
                  value={userCertification.issueDate || ''}
                  placeholder="Issue Date (YYYY/MM/DD)"
                  type="text"
                  onChange={(e: any) => onChange('issueDate', e.target.value)}
                  size="lg"
                />

                {!!errors.issueDate && (
                  <FormError>{errors.issueDate}</FormError>
                )}
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Input
                  id="credentialId"
                  name="credentialId"
                  value={userCertification.credentialId || ''}
                  placeholder="Credential ID"
                  type="text"
                  onChange={(e: any) =>
                    onChange('credentialId', e.target.value)
                  }
                  size="lg"
                />

                {!!errors.credentialId && (
                  <FormError>{errors.credentialId}</FormError>
                )}
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Input
                  id="credentialUrl"
                  name="credentialUrl"
                  value={userCertification.credentialUrl || ''}
                  placeholder="Credential URL"
                  type="text"
                  onChange={(e: any) =>
                    onChange('credentialUrl', e.target.value)
                  }
                  size="lg"
                />

                {!!errors.credentialUrl && (
                  <FormError>{errors.credentialUrl}</FormError>
                )}
              </FormGroup>
            </div>
          </div>
          <div className="row overflow-hidden">
            <div className="col-12 d-flex align-items-center justify-content-start">
              <FormGroup className="mb-0">
                <Input
                  id="visible"
                  name="visible"
                  checked={userCertification.visible || false}
                  type="checkbox"
                  onChange={() =>
                    onChange('visible', !userCertification.visible)
                  }
                  size="lg"
                />
                <Label htmlFor="visible">Visible</Label>
              </FormGroup>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-lg-12 mt-5 d-flex align-items-center justify-content-end">
            <Button type="button" onClick={onClose}>
              <span>Cancel</span>
            </Button>
            <Button
              type="button"
              className="ml-3"
              disabled={Object.values(errors).some(v => !!v)}
              onClick={_onSave}
            >
              <span>Save</span>
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CertificationModal
