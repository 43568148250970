import React from 'react'
import { Spinner } from 'react-bootstrap'

import AvatarPlaceholder from '@/assets/images/avatar-placeholder.jpeg'
import { Logo, Button, Sticky } from '@/components'
import { AppUser } from '@/types'
import { useCV } from '@/hooks'
import { fullName } from '@/utils'

interface Props {
  user: AppUser
  contactable?: boolean
  profileUrl?: string
}

const HeroArea: React.FC<Props> = ({ user, profileUrl, contactable }) => {
  const { downloading, onDownload } = useCV({ user, profileUrl, contactable })
  return (
    <div className="d-flex flex-wrap align-content-start h-100">
      <Sticky className="sticky-top-slider" top="200px">
        <div className="banner-details-wrapper-sticky slide">
          <Logo
            image={{
              src: user.profile?.avatar?.url || AvatarPlaceholder,
              alt: 'user avatar',
            }}
            className="thumbnail"
          />

          <div className="banner-title-area pt--30">
            <h1 className="title">
              Hi, I'm
              <span>
                {fullName({
                  firstName: user.profile?.firstName,
                  middleName: user.profile?.middleName,
                  lastName: user.profile?.lastName,
                })}
              </span>
              {!!user.profile?.title && (
                <div>
                  <span className="span">({user.profile?.title})</span>
                </div>
              )}
            </h1>
            {/* {!!user.profile?.bio && <p className="disc">{user.profile?.bio}</p>} */}
          </div>
          <div className="button-group text-center text-md-left pt--60 pt_md--40 pt_sm--40">
            {contactable && (
              <Button path="#contacts">
                <span>CONTACT ME</span>
              </Button>
            )}
            <Button className="align-items-center" onClick={onDownload}>
              {downloading && <Spinner animation="grow" variant="light" />}
              <span className="mx-2">Download CV</span>
            </Button>
          </div>
        </div>
      </Sticky>
    </div>
  )
}

export default HeroArea
