/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ToastContainer,
  toast,
  ToastOptions,
  TypeOptions as ToastType,
} from 'react-toastify'

import { AppUser, IThemes } from '@/types'
import {
  Admin,
  ExternalLink,
  Setting,
  useSignInMutation,
} from '@/graphql/graphql'
import { useStorage } from '@/hooks/index'
import { ADMIN_TOKEN_KEY, USER_CONFIG_KEY } from '@/constants'
import 'react-toastify/dist/ReactToastify.css'

export interface UserConfig {
  userSlug?: string
  profileUrl?: string
  availableLinks?: ExternalLink[]
  platform?: ExternalLink
  contactable?: boolean
  theme?: IThemes
  isRecorded?: boolean
}

interface SignInParams {
  email?: string
  password?: string
  accessToken?: string
}
type Callback = (status: boolean, message: string) => void

interface IAppContext {
  config: UserConfig
  setting: Partial<Setting> | undefined
  user: AppUser
  admin: Admin | undefined
  authenticated: boolean
  loading: boolean
  onSetConfig: (data: Partial<UserConfig>) => void
  onSetSetting: (data: Partial<Setting> | undefined) => void
  onSetUser: (data: Partial<AppUser>) => void
  onAdminSignIn: (params: SignInParams, callback?: Callback) => void
  onAdminSignOut: (callback?: () => void) => void
  notifyMessage: (
    type: ToastType,
    message: string,
    theme?: 'light' | 'dark',
  ) => void
}

export const AppContext = React.createContext<IAppContext>({
  config: {} as UserConfig,
  setting: undefined,
  user: {} as AppUser,
  admin: undefined,
  authenticated: false,
  loading: false,
  onSetConfig: () => null,
  onSetSetting: () => null,
  onSetUser: () => null,
  onAdminSignIn: () => null,
  onAdminSignOut: () => null,
  notifyMessage: () => null,
})

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [, setToken, unsetToken] = useStorage<string | undefined>(
    ADMIN_TOKEN_KEY,
    undefined,
  )
  const [userConfig, setUserConfig] = useStorage<UserConfig | undefined>(
    USER_CONFIG_KEY,
    {},
  )

  const [config, setConfig] = useState<UserConfig | undefined>()
  const [setting, setSetting] = useState<Partial<Setting> | undefined>()
  const [user, setUser] = useState<AppUser>({} as AppUser)
  const [admin, setAdmin] = useState<Admin | undefined>()
  const [authenticated, setAuthenticated] = useState<boolean>(false)

  const [signIn, { loading: signingIn }] = useSignInMutation()

  useEffect(() => {
    if (!config) {
      setConfig(userConfig || {})
    }
  }, [config, userConfig])

  const onAdminSignIn = useCallback(
    ({ email, password, accessToken }: any, callback: any) => {
      signIn({
        variables: {
          email,
          password,
          accessToken,
        },
        onCompleted: res => {
          if (res?.signIn.data?.accessToken) {
            setUser({} as AppUser)
            setToken(`Bearer ${res.signIn.data.accessToken}`, false, false)
            setAdmin(res.signIn.data.admin)
            setAuthenticated(true)
          } else {
            unsetToken()
            setAdmin(undefined)
            setAuthenticated(false)
          }
          callback(
            res?.signIn?.status || false,
            res?.signIn?.message || 'Email or password is incorrect.',
          )
        },
        onError: err => {
          console.log({ err })
        },
      })
    },
    [setToken, signIn, unsetToken],
  )

  const onAdminSignOut = useCallback(
    (callback?: () => void) => {
      setAdmin(undefined)
      unsetToken()
      if (callback) callback()
    },
    [unsetToken],
  )

  const onSetUser = useCallback((d: Partial<AppUser>) => {
    setUser(u => ({ ...u, ...d }))
  }, [])

  const onSetConfig = useCallback(
    (d: Partial<UserConfig>) => {
      const _config = { ...config, ...d }
      setConfig(_config)
      setUserConfig(_config)
    },
    [config, setUserConfig],
  )

  const onSetSetting = useCallback((d: Partial<Setting> | undefined) => {
    setSetting(d)
  }, [])

  const onNotifyMessage = useCallback(
    (type: ToastType, message: string, theme?: 'light' | 'dark') => {
      const opts: ToastOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: ctx =>
          `${ctx?.defaultClassName} Toastify__toast-theme--${theme || 'light'}`,
      }
      if (type === 'info') {
        toast.info(message, opts)
      } else if (type === 'success') {
        toast.success(message, opts)
      } else if (type === 'warning') {
        toast.warn(message, opts)
      } else if (type === 'error') {
        toast.error(message, opts)
      } else {
        toast(message, opts)
      }
    },
    [],
  )

  const value = useMemo(
    () => ({
      config: config || {},
      setting,
      user,
      admin,
      authenticated,
      loading: signingIn,
      onSetConfig,
      onSetSetting,
      onSetUser,
      onAdminSignIn,
      onAdminSignOut,
      notifyMessage: onNotifyMessage,
    }),
    [
      admin,
      authenticated,
      config,
      setting,
      onAdminSignIn,
      onAdminSignOut,
      onNotifyMessage,
      onSetConfig,
      onSetSetting,
      onSetUser,
      signingIn,
      user,
    ],
  )

  return (
    <AppContext.Provider value={value}>
      {children}
      <ToastContainer />
    </AppContext.Provider>
  )
}
