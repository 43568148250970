import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { IThemes, TThemeMode } from '@/types'
import { useApp } from '@/hooks'
import { URL_PARAMS } from '@/constants'
import { getThemeMode } from '@/utils'

interface IThemeContext {
  theme: Maybe<IThemes>
  mode: TThemeMode
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: undefined,
  mode: 'dark',
})

export const ThemeProvider: React.FC<{
  // eslint-disable-next-line no-unused-vars
  children: (theme: TThemeMode) => React.ReactNode
}> = ({ children }) => {
  const [q] = useSearchParams()
  const { config, setting, onSetConfig } = useApp()

  const [theme, setTheme] = useState<IThemes | undefined>()

  useEffect(() => {
    const _theme = (q.get(URL_PARAMS.THEME) ||
      config?.theme ||
      setting?.theme) as IThemes | undefined
    setTheme(_theme)
    onSetConfig({ theme: _theme })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, setting?.theme, config?.theme])

  const mode = useMemo(() => getThemeMode(theme as string), [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        mode,
      }}
    >
      {children(mode)}
    </ThemeContext.Provider>
  )
}
