/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { ArrowRight } from 'react-feather'

import { ProfilePortfolio } from '@/types'
import PortfolioModal from './portfolioModal'

interface Props {
  data: ProfilePortfolio
  onView: (id: string) => void
  onLike: (id: string) => void
}

const PortfolioCard: React.FC<Props> = ({ data, onView, onLike }) => {
  const [show, setShow] = useState<boolean>(false)

  const onViewPortfolio = useCallback(() => {
    onView(data.id)
    setShow(true)
  }, [data.id, onView])

  return (
    <>
      <li>
        <button type="button" onClick={onViewPortfolio}>
          {data.title} <ArrowRight />
        </button>
      </li>
      <PortfolioModal
        show={show}
        data={data}
        onClose={() => setShow(false)}
        onLike={() => onLike(data.id)}
      />
    </>
  )
}

export default PortfolioCard
