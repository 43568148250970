import React from 'react'

import { Skill } from '@/graphql/graphql'
import { useTheme } from '@/hooks'
import { getSkillIcon } from '@/utils'

interface Props {
  data: Skill[]
}

const SkillArea: React.FC<Props> = ({ data }) => {
  const { mode } = useTheme()

  if (!data.length) return null

  return (
    <div className="rn-skill-area section-height">
      <div className="inner slide">
        <h5 className="title">About My Skill</h5>

        <div className="skill-share-inner pt--100">
          <ul className="skill-share d-flex liststyle">
            {data.map(ex => (
              <li key={ex.name}>{getSkillIcon(ex, mode, 25)}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SkillArea
