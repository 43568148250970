import React from 'react'
import classNames from 'classnames'

import { Maybe } from '@/graphql/graphql'
import { Button, Skeleton } from '@/components/index'
import { ProfileUser } from '@/types'

// import AvatarPlaceholder from '@/assets/images/avatar-placeholder.jpeg'

interface Props {
  id?: string
  data: ProfileUser | undefined
  contactable?: Maybe<boolean>
}

const UserBio: React.FC<Props> = ({ data, contactable, id = 'home' }) => {
  return (
    <div className="container user-bio-container">
      <div className="row">
        <div className="col-lg-12">
          <div className="banner-inner pt--50">
            {/* <div className="thumbnail gradient-border gradient-animation">
              <img
                src={data?.avatar?.url || AvatarPlaceholder}
                className="gradient-border hero-avatar"
                alt="user avatar"
              />
            </div> */}

            {data?.title ? (
              <h1>{data?.title}</h1>
            ) : (
              <Skeleton wrapperClassName="sk-center" className="sk-md" />
            )}

            {data?.bio ? (
              <span className="cd-headline clip is-full-width">
                <span
                  className={classNames('py-3 mt-3', {
                    'with-background': Boolean(data?.backgroundImages.length),
                  })}
                >
                  {data?.bio}
                </span>
              </span>
            ) : (
              <>
                <Skeleton wrapperClassName="mt-3 sk-center" className="sk-sm" />
                <Skeleton wrapperClassName="sk-center" className="sk-md" />
                <Skeleton wrapperClassName="sk-center" className="sk-md" />
              </>
            )}

            {contactable && (
              <div className="button-area">
                <Button key={id} path="#contacts">
                  <span>CONTACT ME</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserBio
