import React from 'react'

import { useApp } from '@/hooks'

import HeroArea from '../components/hero/index-10'
import AboutArea from '../components/about/index-03'
import SkillArea from '../components/skill/index-02'
import ServicesArea from '../components/services/index-05'
import PortfoliosArea from '../components/portfolios/index-05'
import ClientArea from '../components/client/index-02'
import ContactArea from '../components/contact/index-03'

const UserTheme1: React.FC = () => {
  const {
    user,
    setting,
    config: { platform, profileUrl },
  } = useApp()

  return (
    <main className="main-page-wrapper container">
      <div className="row row--30 pt--100 pt_sm--50">
        <div className="col-lg-6">
          <HeroArea
            user={user}
            contactable={!!setting?.showContact}
            profileUrl={profileUrl}
          />
        </div>
        <div className="col-lg-6">
          <div className="sticky-home-wrapper">
            <AboutArea data={user.profile} />
            <SkillArea data={user.resume?.skills || []} />
            <ServicesArea data={user.features || []} />
            <PortfoliosArea data={user.portfolios || []} />
            <ClientArea data={user.clients || []} />
            {setting?.showContact && (
              <ContactArea data={user.profile} platform={platform} />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

export default UserTheme1
