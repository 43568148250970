import { useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { groupBy } from 'lodash'

import {
  ExternalLink,
  Education,
  Experience,
  Skill,
  SkillCategory,
  UserLink,
  Certification,
} from '@/graphql/graphql'
import { AppUser, CVData } from '@/types'
import { fullName } from '@/utils'
import { IS_DEV, AVATAR_DATA_URL } from '@/constants'

interface IUseCV {
  user: AppUser
  profileUrl?: string
  contactable?: boolean
}

export const useCV = ({ user, profileUrl, contactable }: IUseCV) => {
  const [downloading, setDownloading] = useState<boolean>(false)

  const onDownload = useCallback(async () => {
    setDownloading(true)

    const { profile, resume } = user

    const userName = fullName({
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
    })

    const linkedIn: UserLink | undefined = profile.links.find(
      l => l.name === ExternalLink.LinkedIn,
    )
    const interests: string[] | undefined = resume?.interests.length
      ? resume?.interests.map(interest => interest.title)
      : undefined

    const education: (Education & { duration: string })[] | undefined = resume
      ?.education.length
      ? resume.education.map(e => ({
          ...e,
          duration: `${e.startedFrom} - ${e.endedAt ? e.endedAt : 'Now'}`,
        }))
      : undefined

    const experience: (Experience & { duration: string })[] | undefined = resume
      ?.experience.length
      ? resume.experience.map(e => ({
          ...e,
          duration: `${e.startedFrom} - ${e.endedAt ? e.endedAt : 'Now'}`,
        }))
      : undefined

    const certification: Certification[] | undefined = resume?.certification
      .length
      ? resume.certification.map(cert => ({
          ...cert,
          issueDate: `Issued ${moment(cert.issueDate).format('ll')}`,
          credentialId: cert.credentialId
            ? `Credential ID ${cert.credentialId}`
            : '',
        }))
      : undefined

    const _skills = groupBy(resume?.skills || [], s => s.category)
    const skills: { category: SkillCategory; data: Skill[] }[] = Object.entries(
      _skills,
    ).map(([category, data]) => ({
      category: category as SkillCategory,
      data: data.map(d => ({ ...d, rate: 10 * d.rate })),
    }))

    const data: CVData = {
      avatar: profile.avatar?.url || AVATAR_DATA_URL,
      userName,
      profileUrl: (profileUrl || '').replace('&preview=true', ''),
      slogan: profile.slogan,
      title: profile.title,
      bio: profile.bio,
      phone: profile.phone,
      email: profile.email,
      address: profile.address,
      linkedin: linkedIn?.url,
      interests,
      education,
      experience,
      certification,
      skills: skills.length ? skills : undefined,
      showContact: Boolean(contactable),
    }

    const apiEndpoint = IS_DEV
      ? 'http://localhost:5000'
      : `${window.location.protocol}//${window.location.host}/api`

    fetch(`${apiEndpoint}/cv/download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${userName}.pdf`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode?.removeChild(link)
      })
      .finally(() => {
        setDownloading(false)
      })
  }, [user, profileUrl, contactable])

  return { downloading, onDownload }
}
