import React, { useEffect, useMemo, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
  NavigateFunction,
} from 'react-router-dom'

import {
  useUserDataLazyQuery,
  useCreateVisitMutation,
  ExternalLink,
} from '@/graphql/graphql'
import { URL_PARAMS } from '@/constants'
import {
  getIdNLinks,
  normalizeUserData,
  notFoundPath,
  getSlugFromUrl,
  booleanfy,
} from '@/utils/index'
import { LoadingWrapper } from '@/components/index'
import { useApp } from '@/hooks/index'
import 'react-loading-skeleton/dist/skeleton.css'

const UserInitialPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const [q] = useSearchParams()
  const { config } = useApp()

  const { onSetUser, onSetConfig, onSetSetting } = useApp()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [userId, setUserId] = useState<string>('')
  const [isDefaultUser, setIsDefaultUser] = useState<boolean>(false)

  const [fetchUserData] = useUserDataLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: userRes => {
      if (userRes) {
        const sharableLinks = (userRes.setting.data?.sharableLinks || []).map(
          s => s.key,
        )
        setUserId(userRes.profile?.data?.id || '')
        onSetSetting(userRes.setting.data || undefined)
        onSetConfig({ userSlug: userRes.profile.data?.slug })
        onSetUser(normalizeUserData(userRes, sharableLinks))
        setIsDefaultUser(!!userRes.profile.data?.isDefault)
        setLoaded(true)
      } else {
        setLoaded(true)
        navigate(notFoundPath)
      }
    },
    onError: () => {
      setLoaded(true)
      navigate(notFoundPath)
    },
  })

  const [recordVisit] = useCreateVisitMutation()

  const { userSlug, platform, profileUrl } = useMemo(() => {
    const {
      userSlug: __userSlug,
      profileUrl: __profileUrl,
      platform: __platform,
    } = config || {}

    const { userSlug: _userSlug } = getIdNLinks(params[URL_PARAMS.SLUG] || '')

    return {
      userSlug:
        _userSlug || __userSlug || getSlugFromUrl(window.location.host) || '',
      platform: (q.get(URL_PARAMS.REF) || __platform || '') as ExternalLink,
      profileUrl: q.get(URL_PARAMS.CONFIG)
        ? window.location.href
        : __profileUrl || window.location.href,
    }
  }, [params, q, config])

  useEffect(() => {
    onSetConfig({
      userSlug,
      profileUrl,
      platform,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, profileUrl, userSlug])

  useEffect(() => {
    setLoaded(false)
    fetchUserData({
      variables: {
        idOrSlug: userSlug,
      },
    })
  }, [fetchUserData, userSlug])

  useEffect(() => {
    const isPreview = booleanfy(q.get(URL_PARAMS.PREVIEW))

    if (userId && !isPreview) {
      recordVisit({
        variables: {
          input: {
            from:
              platform ||
              (isDefaultUser ? ExternalLink.Other : ExternalLink.Upwork),
            userAgent: navigator.userAgent,
            userId,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordVisit, userId])

  useEffect(() => {
    if (!loaded) return
    if (userId) {
      navigate(`/`)
    }
  }, [navigate, loaded, userId])

  return (
    <LoadingWrapper loading className="mh-100vh mw-100vw" isEmpty={false} />
  )
}

export default UserInitialPage
