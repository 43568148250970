import React from 'react'
import classNames from 'classnames'

import './styles.scss'

interface Props {
  role: string[]
  className?: string
}

const Role: React.FC<Props> = ({ role, className }) => {
  if (!role.length) return null

  return (
    <div className={classNames('role-container', className)}>
      {role.map((r: string, index: number) => (
        <div key={index} className="role-item">
          <span>{r}</span>
        </div>
      ))}
    </div>
  )
}

export default Role
