import React, { useCallback } from 'react'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

import { ProfileUser } from '@/types'
import { useTheme } from '@/hooks'

import UserSlider from '../userSlider'
import UserBio from '../userBio/'
import { particleParams } from './constant'

interface Props {
  id?: string
  data: ProfileUser | undefined
  contactable?: boolean
}

const HeroArea: React.FC<Props> = ({ data, contactable, id = 'home' }) => {
  const { mode } = useTheme()

  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine)
  }, [])

  return (
    <section
      id={id}
      className={`slider-style-5 rn-section-gap ${
        (data?.backgroundImages || []).length > 1 ? 'pt--0' : 'pt--75'
      } pb--50 align-items-center with-particles`}
    >
      <Particles
        className="particles"
        canvasClassName="canvas-area"
        init={particlesInit}
        params={particleParams(mode)}
      />
      <UserSlider images={data?.backgroundImages} />
      <UserBio data={data} contactable={contactable} id={id} />
    </section>
  )
}

export default HeroArea
