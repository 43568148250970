import React, { useCallback, useMemo, useRef } from 'react'

import { ConfirmButton } from '@/components/index'
import { URL_PARAMS } from '@/constants'

interface Props {
  slug: string | undefined
  platforms: string[]
  themes: string[]
  contactable: boolean
}

const SharableLink: React.FC<Props> = ({ slug, platforms, themes }) => {
  const linkRef = useRef<HTMLInputElement>(null)

  const siteUrl = useMemo(() => {
    return `${window.location.protocol}//${window.location.host}`
  }, [])

  const sharableLink = useMemo(() => {
    const platform = platforms[0]
    const concatenation = siteUrl.endsWith('/') ? '' : '/'
    let sLink = `${siteUrl}${concatenation}${slug || ''}`

    sLink += `?${URL_PARAMS.THEME}=${themes.toString()}`
    if (platform) {
      sLink += `&ref=${platform}`
    }

    return sLink
  }, [platforms, siteUrl, slug, themes])

  const onCopy = useCallback(() => {
    if (linkRef.current && navigator.clipboard && window.isSecureContext) {
      linkRef.current.select()
      linkRef.current.setSelectionRange(0, 99999)

      // navigator clipboard api method'
      navigator.clipboard.writeText(linkRef.current.value)
    }
  }, [])

  if (!sharableLink) return null

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="px-3 py-2 position-relative sharable-link">
          <a
            href={`${sharableLink}&${URL_PARAMS.PREVIEW}=true`}
            target="_blank"
          >
            {sharableLink}
          </a>
          <input
            ref={linkRef}
            type="text"
            className="link-value"
            value={sharableLink}
            onChange={() => null}
          />
          <div className="copy-button">
            <ConfirmButton
              className="link-action-button"
              iconClassName="action-icon"
              iconName="copy"
              iconSize={14}
              variant="primary"
              wait={false}
              onClick={onCopy}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharableLink
