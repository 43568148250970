import React from 'react'
import { Award } from 'react-feather'
import { Anchor, Icon } from '@/components/index'
import moment from 'moment-timezone'

import { ProfileCertification } from '@/types'

interface Props {
  id?: string
  data: ProfileCertification[]
}

const CertificationArea: React.FC<Props> = ({ data, id = 'certification' }) => {
  return (
    <div className="rn-service-area rn-section-gap section-separator" id={id}>
      <div className="row">
        {data.map((certification: ProfileCertification) => (
          <div
            className="col-12 col-md-6 col-lg-4 mb-3"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
            key={`certification-${certification.index}`}
          >
            <div className="rn-service service-card-one certification-item">
              <div className="inner">
                <div className="content">
                  <div className="w-100 d-flex">
                    <div className="cert-icon-container">
                      <Award size={18} color="var(--color-subtitle)" />
                    </div>
                    <div className="d-flex flex-grow-1 flex-column pl-3">
                      <p className="certification-name mb-1">
                        {certification.name}
                      </p>
                      <div className="certification-org">
                        {certification.issuingOrg}
                      </div>
                      <div className="certification-date">
                        Issued {moment(certification.issueDate).format('ll')}
                      </div>
                      {!!certification.credentialId && (
                        <div className="certification-id">
                          <span>Credential ID</span>
                          <span>{certification.credentialId}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {!!certification.credentialUrl && (
                    <Anchor
                      className="read-more-button"
                      path={certification.credentialUrl}
                    >
                      <Icon name="arrow-right" />
                    </Anchor>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CertificationArea
