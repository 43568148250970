/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { X } from 'react-feather'

import {
  ExternalLink,
  Setting,
  useUpsertSettingMutation,
  useUserSettingQuery,
} from '@/graphql/graphql'
import { IThemes } from '@/types'
import { IS_SSL } from '@/constants'

import SharableLink from './sharableLink'
import ExternalLinks from './externalLinks'
import Themes from './themes'
import UserSlug from './userSlug'
import Contactable from './contactable'
import { omit } from 'lodash'

interface Props {
  show: boolean
  userId: string | undefined
  slug: string | undefined
  onClose: () => void
}

const SettingsModal: React.FC<Props> = ({ show, userId, slug, onClose }) => {
  const [links, setLinks] = useState<string[]>([])
  const [platforms, setPlatforms] = useState<string[]>([])
  const [themes, setThemes] = useState<string[]>([IThemes.Default])
  const [userSlug, setUserSlug] = useState<boolean>(!IS_SSL)
  const [contactable, setContactable] = useState<boolean>(false)

  const [setting, setSetting] = useState<Maybe<Setting>>()
  const [upsertSetting] = useUpsertSettingMutation()

  useUserSettingQuery({
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: {
      idOrSlug: userId || '',
    },
    onCompleted: res => {
      setSetting(res.setting.data)
      setLinks(res.setting.data?.sharableLinks?.map(l => l.key) || [])
      setThemes([res.setting.data?.theme || IThemes.Default])
      setContactable(!!res.setting.data?.showContact)
    },
  })

  const onUpdateSetting = useCallback(
    (d: Partial<Setting>) => {
      const data = {
        ...(setting || {}),
        userId: userId || '',
        ...d,
      }
      upsertSetting({
        variables: {
          input: omit(data, ['createdAt', 'updatedAt']),
        },
        onCompleted: res => {
          setSetting(res.upsertSetting.data)
        },
      })
    },
    [setting, upsertSetting, userId],
  )

  const onChange = useCallback(
    (name: string, value: string | boolean) => {
      if (name === 'links') {
        const _links = [...links].filter(_l => _l !== value)
        if (!links.includes(value as string)) {
          _links.push(value as string)
        }
        setLinks(_links)
        onUpdateSetting({
          sharableLinks: _links.map(l => ({
            key: l as ExternalLink,
            value: l,
          })),
        })
      } else if (name === 'platforms') {
        setPlatforms([value as string])
      } else if (name === 'themes') {
        setThemes([value as string])
        onUpdateSetting({
          theme: value as string,
        })
      } else if (name === 'contactable') {
        setContactable(value as boolean)
        onUpdateSetting({
          showContact: !!value,
        })
      } else if (name === 'userSlug') {
        setUserSlug(value as boolean)
      }
    },
    [links, onUpdateSetting],
  )

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title" className="h6">
          Generate sharable user link
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">
            <X />
          </span>
        </button>
      </Modal.Header>
      <Modal.Body className="sharable-link-modal">
        <div className="form-wrapper form-dark no-shadow py-0 px-3">
          <SharableLink
            slug={userSlug ? slug : ''}
            platforms={platforms}
            themes={themes}
            contactable={contactable}
          />
          <UserSlug name="userSlug" value={userSlug} onChange={onChange} />
          <ExternalLinks
            name="platforms"
            value={platforms}
            title="Platforms"
            description="Choose a platform where you want to share with."
            onChange={onChange}
          />
          <ExternalLinks
            name="links"
            value={links}
            title="External link"
            description="Choose links you want to show in the portfolio."
            onChange={onChange}
          />
          <Themes
            name="themes"
            value={themes}
            title="Theme"
            description="Choose your favorite theme."
            onChange={onChange}
          />
          <Contactable
            name="contactable"
            value={contactable}
            onChange={onChange}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SettingsModal
