import React from 'react'

import { useApp } from '@/hooks'

import Header from '../components/header/index-02'
import Footer from '../components/footer/index-01'
import HeroArea from '../components/hero/index-03'
import ServicesArea from '../components/services/index-04'
import PortfoliosArea from '../components/portfolios/index-01'
import ResumeArea from '../components/resume/index-01'
import ClientArea from '../components/client/index-01'
import ContactArea from '../components/contact/index-01'
import CVDownload from '../components/userCV'

const UserThemeDefault: React.FC = () => {
  const {
    user,
    setting,
    config: { platform, profileUrl },
  } = useApp()

  return (
    <div className="container">
      <Header contactable={!!setting?.showContact} />
      <main className="page-wrapper-two">
        <HeroArea data={user.profile} contactable={!!setting?.showContact} />
        <ServicesArea data={user.features || []} />
        <PortfoliosArea data={user.portfolios || []} />
        <ResumeArea data={user.resume} />
        <ClientArea data={user.clients || []} />
        {setting?.showContact && (
          <ContactArea data={user.profile} platform={platform} />
        )}
        <Footer data={user.profile} className="section-separator" />
        <CVDownload
          user={user}
          profileUrl={profileUrl}
          contactable={!!setting?.showContact}
        />
      </main>
    </div>
  )
}

export default UserThemeDefault
