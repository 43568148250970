import React from 'react'

import { Skill } from '@/graphql/graphql'
import { getSkillIcon } from '@/utils/index'
import { useTheme } from '@/hooks'

import './styles.scss'

interface Props {
  expertise: Skill[]
}

const Expertise: React.FC<Props> = ({ expertise }) => {
  const { mode } = useTheme()

  if (!expertise.length) return null

  return (
    <div className="mt-3 py-4 px-3 user-expertise">
      <div className="pb-3 expertise-title">My expertise</div>
      {expertise.map((ex: Skill, index: number) => (
        <div
          key={index}
          className={`mx-2 mb-4 user-skill-icon icon-${mode}`}
          title={ex.name}
        >
          {getSkillIcon(ex, mode)}
        </div>
      ))}
    </div>
  )
}

export default Expertise
