import {
  DiNodejsSmall,
  DiPhp,
  DiPython,
  DiLaravel,
  DiRuby,
  DiReact,
  DiAndroid,
} from 'react-icons/di'
import {
  SiTypescript,
  SiNestjs,
  SiRubyonrails,
  SiNextdotjs,
  SiGraphql,
  SiTailwindcss,
  SiCsharp,
  SiSolidity,
  SiBlockchaindotcom,
  SiDjango,
  SiVuedotjs,
  SiNuxtdotjs,
  SiAngularjs,
  SiIos,
  SiCsswizardry,
} from 'react-icons/si'

import { Skill } from '@/graphql/graphql'
import { TThemeMode } from '@/types'
import { getInitials } from './misc'

export const getSkillIcon = (
  ex: Skill,
  themeMode: TThemeMode,
  size = 22,
): JSX.Element => {
  const name = ex.name.replace(/ /g, '').toLowerCase()
  const color = themeMode === 'light' ? 'var(--color-body-white)' : '#FFFFFF'

  if (name.startsWith('typescript')) {
    return <SiTypescript size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('node')) {
    return <DiNodejsSmall size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('nest')) {
    return <SiNestjs size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('rubyonrails')) {
    return <SiRubyonrails size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('ruby')) {
    return <DiRuby size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('php')) {
    return <DiPhp size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('python')) {
    return <DiPython size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('django')) {
    return <SiDjango size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('laravel')) {
    return <DiLaravel size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('react')) {
    return <DiReact size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('next')) {
    return <SiNextdotjs size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('vue')) {
    return <SiVuedotjs size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('nuxt')) {
    return <SiNuxtdotjs size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('angular')) {
    return <SiAngularjs size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('tailwindcss')) {
    return <SiTailwindcss size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('c#')) {
    return <SiCsharp size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('graphql')) {
    return <SiGraphql size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('solidity')) {
    return <SiSolidity size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('blockchain')) {
    return <SiBlockchaindotcom size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('android')) {
    return <DiAndroid size={size} color={color} title={ex.name} />
  }
  if (name.startsWith('ios')) {
    return <SiIos size={size} color={color} title={ex.name} />
  }
  if (name.includes('css')) {
    return <SiCsswizardry size={size} color={color} title={ex.name} />
  }

  return <span className="custom-expertise">{getInitials(ex.name)}</span>
}
