/* eslint-disable no-unused-vars */
import React from 'react'
import classNames from 'classnames'

import ImgThemeD from '@/assets/images/themes/themeD.png'
import ImgThemeDL from '@/assets/images/themes/themeD-l.png'
import ImgTheme1 from '@/assets/images/themes/theme1.png'
import ImgTheme1L from '@/assets/images/themes/theme1-l.png'
import { IThemes } from '@/types'

interface Props {
  name: string
  value: string[]
  title: string
  description: string
  onChange: (name: string, value: string) => void
}

const themeImages = {
  [IThemes.Default]: ImgThemeD,
  [IThemes.DefaultL]: ImgThemeDL,
  [IThemes.Theme1]: ImgTheme1,
  [IThemes.Theme1L]: ImgTheme1L,
}

const Themes: React.FC<Props> = ({
  name,
  value,
  title,
  description,
  onChange,
}) => {
  return (
    <div className="row mb-2 links-row">
      <div className="col-12">
        <div className="link-label">{title}</div>
        <div className="link-description mb-4">{description}</div>
        <div className="w-100 ml-2 d-flex align-items-center flex-wrap">
          {Object.entries(IThemes).map(([k, v]) => (
            <div key={k} className="theme-item-container">
              <div
                className={classNames(
                  'mb-2 d-flex align-items-center position-relative theme-item',
                  value.includes(v) && 'theme-selected',
                )}
                onClick={() => onChange(name, v)}
              >
                <img src={themeImages[v as IThemes]} alt={k} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Themes
