import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import classNames from 'classnames'

import { NoData } from '@/components/index'
import { Children, TThemeMode } from '@/types'
import { useTheme } from '@/hooks'

interface Props {
  className?: string
  theme?: TThemeMode
  loading: boolean
  isEmpty: boolean
  children?: Children
}

const LoadingWrapper: React.FC<Props> = ({
  className,
  loading,
  isEmpty,
  children,
}) => {
  const { mode } = useTheme()

  if (loading) {
    return (
      <div className={classNames(`loading-wrapper ${mode}`, className)}>
        <Spinner
          animation="grow"
          variant={mode === 'light' ? 'dark' : 'light'}
        />
      </div>
    )
  }

  if (isEmpty) {
    return <NoData />
  }

  return <>{children}</>
}

export default LoadingWrapper
