import React from 'react'

import Feedback from './feedback'

interface Props {
  logo: Maybe<string>
  name: string
  clientInfo: Maybe<string>
  feedback: Maybe<string>
  url: Maybe<string>
  onReadMore: () => void
}

const ClientCard: React.FC<Props> = ({
  logo,
  name,
  clientInfo,
  feedback,
  onReadMore,
  ...rest
}) => {
  return (
    <div className="testimonial-inner" {...rest}>
      <div className="testimonial-header">
        <div className="thumbnail">
          {!!logo && (
            <div className="card-thumbnail">
              <img src={logo} alt={name} />
            </div>
          )}
        </div>
        <h5 className="ts-header">
          <span className="text-color-primary">{clientInfo}</span> {name}
        </h5>
      </div>
      <Feedback
        className="testimonial-body"
        clientInfo={clientInfo}
        feedback={feedback}
        onReadMore={onReadMore}
      />
    </div>
  )
}

export default ClientCard
