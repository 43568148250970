import React from 'react'
import Truncate from 'react-truncate'

interface Props {
  className?: string
  clientInfo: Maybe<string>
  feedback: Maybe<string>
  onReadMore: () => void
}

const Feedback: React.FC<Props> = ({
  className,
  clientInfo,
  feedback,
  onReadMore,
}) => {
  if (!feedback) return null

  return (
    <div className={`client-feedback ${className || ''}`}>
      <Truncate
        lines={2}
        ellipsis={
          <span>
            ...
            <span className="mx-2 read-more" onClick={onReadMore}>
              Read more
            </span>
          </span>
        }
      >
        {feedback}
      </Truncate>
      {!!clientInfo && <div className="mt-1 client-name">- {clientInfo}</div>}
    </div>
  )
}

export default Feedback
