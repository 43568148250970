import React, { useState, useCallback } from 'react'

import { Slider, SliderItem } from '@/components/index'
import { ProfileClient } from '@/types'
import ClientCard from './client02'
import FeedbackModal from './feedbackModal'

import './styles.scss'

interface Props {
  data: ProfileClient[]
}

const ClientsArea: React.FC<Props> = ({ data }) => {
  const [showModal, setShowModal] = useState(false)
  const [feedbackData, setFeedbackData] = useState<
    { feedback: Maybe<string>; clientInfo: Maybe<string> } | undefined
  >()

  const onReadMore = useCallback(
    (_feedback: Maybe<string>, _clientInfo?: Maybe<string>) => {
      if (_feedback) {
        setFeedbackData({ feedback: _feedback, clientInfo: _clientInfo })
        setShowModal(true)
      }
    },
    [],
  )

  const onClose = useCallback(() => {
    setShowModal(false)
    setFeedbackData(undefined)
  }, [])

  if (!data.length) return null

  return (
    <>
      <div
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-delay="200"
        data-aos-once="true"
        className="rn-testimonial-area testimonial-style-2 section-height"
      >
        <div className="inner">
          <h5 className="title">Testimonial</h5>
          <Slider
            options={{ arrows: true }}
            prevIcon="ChevronLeft"
            nextIcon="ChevronRight"
            className="rn-testimonial-carousel testimonial-item-one rn-slick-dot-style dot-position-left slick-arrow-style-one arrow-top-align"
          >
            {data.map((client: ProfileClient) => (
              <SliderItem key={client.id}>
                <ClientCard
                  key={client.id}
                  logo={client.logo?.url}
                  name={client.name}
                  clientInfo={client.clientInfo}
                  feedback={client.feedback}
                  onReadMore={() =>
                    onReadMore(client.feedback, client.clientInfo)
                  }
                  url={client.url}
                />
              </SliderItem>
            ))}
          </Slider>
        </div>
      </div>
      <FeedbackModal show={showModal} data={feedbackData} onClose={onClose} />
    </>
  )
}

export default ClientsArea
