import React from 'react'
import { DownloadCloud } from 'react-feather'
import { Spinner } from 'react-bootstrap'

import { AppUser } from '@/types'
import { useTheme, useCV } from '@/hooks'

import './styles.scss'

interface Props {
  user: AppUser
  profileUrl?: string
  contactable?: boolean
}

const UserCV: React.FC<Props> = ({ user, profileUrl, contactable }) => {
  const { mode } = useTheme()
  const { downloading, onDownload } = useCV({
    user,
    profileUrl,
    contactable,
  })

  return (
    <div className={`user-csv-button ${mode}`} title="Download CV">
      <button onClick={onDownload} disabled={downloading}>
        {downloading ? (
          <Spinner animation="grow" variant="light" />
        ) : (
          <DownloadCloud />
        )}
        <span>CV</span>
      </button>
    </div>
  )
}

export default UserCV
