import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloLink } from '@apollo/client/core'
import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'

import { IS_DEV } from '@/constants'
import authLink from './authLink'

const apiEndpoint = IS_DEV
  ? 'http://localhost:5000/graphql'
  : `${window.location.protocol}//${window.location.host}/api/graphql`

const wsEndpoint = IS_DEV
  ? 'ws://localhost:5000/graphql'
  : `${window.location.protocol.includes('https') ? 'wss:' : 'ws:'}//${
      window.location.host
    }/api/graphql`

const linkOptions = {
  // credentials: "include",
  uri: apiEndpoint,
}

const uploadLink = createUploadLink(linkOptions)

const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
  },
})

const link = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  uploadLink as unknown as ApolloLink,
)

export default new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: authLink.concat(link as ApolloLink),
})
