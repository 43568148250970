import React, { useRef } from 'react'
import classNames from 'classnames'

import { KeyValue } from '@/types'

import Feedback from './feedback'

interface Props {
  logo: Maybe<string>
  name: string
  clientInfo: Maybe<string>
  feedback: Maybe<string>
  url: Maybe<string>
  onReadMore: () => void
}

const ClientCard: React.FC<Props & KeyValue<any>> = ({
  logo,
  name,
  clientInfo,
  feedback,
  url,
  onReadMore,
  ...rest
}) => {
  const clientRef = useRef<HTMLDivElement>(null)

  return (
    <div className="main-content" {...rest}>
      <div className="inner text-center">
        <div className="thumbnail">
          <a
            href={url || '#!'}
            className={classNames('client-logo', {
              emptyLogo: !logo,
            })}
            target={url ? '_blank' : undefined}
          >
            {logo ? <img src={logo} alt={name} /> : <span>{name}</span>}
          </a>
        </div>
        <div className="client-name" ref={clientRef}>
          <span>
            <a href={url || '#!'} target={url ? '_blank' : undefined}>
              {name}
            </a>
          </span>
        </div>

        <div className="separator" />
        <Feedback
          clientInfo={clientInfo}
          feedback={feedback}
          onReadMore={onReadMore}
        />
      </div>
    </div>
  )
}

export default ClientCard
