import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@/components/index'

import Button from './index'
import classNames from 'classnames'

interface Props {
  className?: string
  onBack?: () => void
}

const BackButton: React.FC<Props> = ({ className = '', onBack }) => {
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    if (onBack) {
      onBack()
    } else {
      navigate(-1)
    }
  }, [navigate, onBack])

  return (
    <div className={classNames('back-button', { [className]: !!className })}>
      <Button onClick={onClick}>
        <Icon name="arrow-left" size={22} />
      </Button>
    </div>
  )
}

export default BackButton
