import React from 'react'

import { ProfileUser } from '@/types'

interface Props {
  data: ProfileUser | undefined
}

const AboutArea: React.FC<Props> = ({ data }) => {
  return (
    <div className="rn-about-area section-height">
      <div className="inner">
        <h5 className="title">About Me</h5>
        <p className="about-disc">{data?.bio}</p>
      </div>
    </div>
  )
}

export default AboutArea
