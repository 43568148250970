import React from 'react'
import { useLocation, Navigate, Location, Outlet } from 'react-router-dom'

import { signInPath } from '@/utils'
import { useApp } from '@/hooks'

const AuthWrapper: React.FC = () => {
  const { authenticated } = useApp()
  const location: Location = useLocation()

  if (!authenticated) {
    return <Navigate to={signInPath} state={{ from: location }} replace />
  }

  return <Outlet />
}

export default AuthWrapper
