import React, { useCallback } from 'react'

import {
  useIncreasePortfolioViewsMutation,
  useIncreasePortfolioLikesMutation,
} from '@/graphql/graphql'
import { ProfilePortfolio } from '@/types'
import { updatePortfolioNum } from '@/utils'
import { useApp } from '@/hooks'
import PortfolioCard from './portfolio05'

interface Props {
  data: ProfilePortfolio[]
}

const PortfolioArea: React.FC<Props> = ({ data }) => {
  const { user, onSetUser } = useApp()
  const [increaseView] = useIncreasePortfolioViewsMutation()
  const [increaseLike] = useIncreasePortfolioLikesMutation()

  const onView = useCallback(
    (id: string) => {
      increaseView({
        variables: { id },
        onCompleted: res => {
          if (res?.increasePortfolioViews?.data) {
            const newPortfolios: ProfilePortfolio[] = updatePortfolioNum(
              user.portfolios,
              res.increasePortfolioViews.data,
            )

            onSetUser({ portfolios: newPortfolios })
          }
        },
      })
    },
    [increaseView, onSetUser, user.portfolios],
  )

  const onLike = useCallback(
    (id: string) => {
      increaseLike({
        variables: { id },
        onCompleted: res => {
          if (res?.increasePortfolioLikes?.data) {
            const newPortfolios: ProfilePortfolio[] = updatePortfolioNum(
              user.portfolios,
              res.increasePortfolioLikes.data,
            )
            onSetUser({ portfolios: newPortfolios })
          }
        },
      })
    },
    [increaseLike, onSetUser, user.portfolios],
  )

  if (!data.length) return null

  return (
    <div
      data-aos="fade-left"
      data-aos-duration="500"
      data-aos-delay="200"
      data-aos-once="true"
      className="rn-portfolio-area single-card-sticky section-height"
    >
      <div className="inner">
        <h5 className="title">My Portfolio</h5>
        <ul className="card-list">
          {data.map(datum => (
            <PortfolioCard
              key={datum.id}
              data={datum}
              onView={onView}
              onLike={onLike}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PortfolioArea
