import React, { useState, useCallback } from 'react'
import { ArrowRight } from 'react-feather'

import { ProfileFeature } from '@/types'
import ServiceModal from './serviceModal'

interface Props {
  data: ProfileFeature[]
}

const ServicesArea: React.FC<Props> = ({ data }) => {
  const [showModal, setShowModal] = useState(false)
  const [service, setService] = useState<
    { title: string; description: Maybe<string> } | undefined
  >()

  const onViewService = useCallback(
    (title: string, description: Maybe<string>) => {
      setService({ title, description })
      setShowModal(true)
    },
    [],
  )

  const onClose = useCallback(() => {
    setShowModal(false)
    setService(undefined)
  }, [])

  if (!data.length) return null

  return (
    <>
      <div
        data-aos="fade-left"
        data-aos-duration="500"
        data-aos-delay="200"
        data-aos-once="true"
        className="rn-skill-area single-card-sticky section-height"
      >
        <div className="inner">
          <h5 className="title">My Services</h5>
          <ul className="card-list">
            {data.map(datum => (
              <li key={datum.id}>
                <button
                  type="button"
                  onClick={() => onViewService(datum.title, datum.description)}
                >
                  {datum.title} <ArrowRight />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ServiceModal
        show={showModal}
        title={service?.title}
        description={service?.description}
        onClose={onClose}
      />
    </>
  )
}

export default ServicesArea
