import React, { useCallback, useEffect, useState } from 'react'

import {
  Config,
  ConfigInput,
  useAdminConfigQuery,
  useUpsertConfigMutation,
  AdminType,
} from '@/graphql/graphql'
import { KeyValue } from '@/types'
import { useApp } from '@/hooks'
import { Button, LoadingWrapper } from '@/components/index'
import ConfigSectionTitle from '@/pages/Admin/components/adminSectionTitle'

import ConfigBlock from './components/config'
import TelegramConnect from './components/telegramConnect'

import './styles.scss'

const ConfigsPage: React.FC = () => {
  const { notifyMessage, admin } = useApp()
  const [config, setConfig] = useState<ConfigInput>({} as ConfigInput)
  const [errors, setErrors] = useState<KeyValue<string>>({})

  const { data, loading } = useAdminConfigQuery({
    fetchPolicy: 'no-cache',
  })
  const [upsertConfig, { loading: upsertingConfig }] = useUpsertConfigMutation()

  const initConfig = useCallback((data: Maybe<Config>) => {
    setConfig({
      dropBoxToken: data?.dropBoxToken || '',
      telegramToken: data?.telegramToken || '',
      slackToken: data?.slackToken || '',
      slackChannel: data?.slackChannel || '',
    })
  }, [])

  useEffect(() => {
    initConfig(data?.config.data)
  }, [data?.config.data, initConfig])

  const validate = useCallback(() => {
    let valid = true

    if (!config.dropBoxToken) {
      valid = false

      setErrors({ dropBoxToken: 'This field is required' })
    }

    return valid
  }, [config.dropBoxToken])

  const onChange = useCallback(
    (group: 'config' | 'config', name: string, value: string) => {
      const _error: KeyValue<string> = {}

      if (group === 'config') {
        if (name === 'dropBoxToken') {
          if (value) {
            _error[name] = ''
          } else {
            _error[name] = 'This field is required'
          }
        }

        setConfig({ ...config, [name]: value })
      }
      setErrors(e => ({ ...e, ..._error }))
    },
    [config],
  )

  const onSave = useCallback(
    (e?: any) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (!validate()) return

      upsertConfig({
        variables: {
          input: config,
        },
        onCompleted: res => {
          notifyMessage(
            res.upsertConfig.status ? 'success' : 'error',
            res.upsertConfig.message,
          )

          initConfig(res.upsertConfig.data)
        },
      })
    },
    [initConfig, notifyMessage, config, upsertConfig, validate],
  )

  return (
    <section className="rn-section-gap pb--110 align-items-center pt--75">
      <div className="container configs-page">
        <ConfigSectionTitle title="Config" />
        {admin?.type === AdminType.SuperAdmin && (
          <>
            <div className="row">
              <LoadingWrapper loading={loading} isEmpty={!config}>
                <ConfigBlock
                  data={config}
                  errors={errors}
                  onChange={(name: string, value: string) =>
                    onChange('config', name, value)
                  }
                />
              </LoadingWrapper>
            </div>

            <div className="row">
              <div className="col-12 col-md-10 mt-5 d-flex justify-content-end">
                <Button
                  type="submit"
                  disabled={upsertingConfig}
                  onClick={onSave}
                >
                  <span>{loading ? 'Saving' : 'Save'}</span>
                </Button>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <TelegramConnect adminId={admin?.id} tgChatId={admin?.tgChatId} />
        </div>
      </div>
    </section>
  )
}

export default ConfigsPage
