import React, { useState, useEffect } from 'react'
import {
  Navigate,
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import {
  ExternalLink,
  useCreateVisitMutation,
  useUserDataLazyQuery,
} from '@/graphql/graphql'
import { IThemes } from '@/types'
import { notFoundPath, normalizeUserData, booleanfy } from '@/utils/index'
import { URL_PARAMS } from '@/constants'
import { useApp, useTheme } from '@/hooks/index'
import { LoadingWrapper } from '@/components/index'
import UserThemeDefault from './themes/default/index.v2'
import UserTheme1 from './themes/theme1/index.v2'
import 'react-loading-skeleton/dist/skeleton.css'

const UserPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const [q] = useSearchParams()
  const { config } = useApp()
  const { theme } = useTheme()
  const [loading, setLoading] = useState<boolean>(true)
  const [isDefaultUser, setIsDefaultUser] = useState<boolean>(false)

  const { user, onSetUser, onSetConfig, onSetSetting } = useApp()
  const [recordVisit] = useCreateVisitMutation()

  const [fetchUserData] = useUserDataLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: userRes => {
      if (userRes) {
        const sharableLinks = (userRes.setting.data?.sharableLinks || []).map(
          s => s.key,
        )
        onSetSetting(userRes.setting.data || undefined)
        onSetUser(normalizeUserData(userRes, sharableLinks))
        onSetConfig({ userSlug: userRes.profile.data?.slug })
        setIsDefaultUser(!!userRes.profile.data?.isDefault)
        setLoading(false)
      }
    },
    onError: () => {
      navigate(notFoundPath)
    },
  })

  useEffect(() => {
    if (!loading) return
    if (!Object.keys(user).length) {
      fetchUserData({
        variables: {
          idOrSlug: config?.userSlug || '',
        },
      })
    } else {
      setLoading(false)
    }
  }, [fetchUserData, user, config?.userSlug, loading])

  useEffect(() => {
    if (config?.isRecorded) return
    if (!config?.userSlug) return
    const isPreview = booleanfy(q.get(URL_PARAMS.PREVIEW))

    if (user?.profile?.id && !isPreview) {
      recordVisit({
        variables: {
          input: {
            from: (q.get(URL_PARAMS.REF) ||
              (isDefaultUser
                ? ExternalLink.Other
                : ExternalLink.Upwork)) as ExternalLink,
            userAgent: navigator.userAgent,
            userId: user.profile.id,
          },
        },
        onCompleted: () => {
          onSetConfig({ isRecorded: true })
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDefaultUser,
    q,
    recordVisit,
    user?.profile?.id,
    config?.isRecorded,
    config?.userSlug,
  ])

  if (loading) {
    return (
      <LoadingWrapper loading className="mh-100vh mw-100vw" isEmpty={false} />
    )
  }

  if (config?.userSlug && !Object.keys(user).length) {
    navigate(notFoundPath)
  }

  if (
    !theme ||
    [IThemes.Default, IThemes.DefaultL].includes(theme as IThemes)
  ) {
    return <UserThemeDefault />
  }
  if (theme && [IThemes.Theme1, IThemes.Theme1L].includes(theme as IThemes)) {
    return <UserTheme1 />
  }

  return <Navigate to={notFoundPath} replace />
}

export default UserPage
